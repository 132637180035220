.burger-avoid {
    margin-top: 90px;
    margin-left: 4%;
    margin-right: 4%;
}

.box-shadow {
    box-shadow: 0px 0px 13px 0px #2e2e35ba !important;
}

.row-shadow {
    box-shadow: 3px 3px 5px #2e2e35ba;
}

.row-bg {
    background: #f2f2f9ba;
}

.large {
    font-size: 20px;
}
