.form-check {
    width: 30px;
    height: 30px;
}

.button-right {
    width: fit-content;
    float: right;
}

#headerCheckbox {
    width: auto !important;
}
